export const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={52} fill="none">
    <path
      fill="#2D2D2D"
      d="M19.225 51.646c-.259 0-.86-1.382-1.144-2.454-.162-.61.043-1.295.637-1.506a1.629 1.629 0 0 1 1.044-.027c.6.193.738.92.575 1.528-.293 1.098-.849 2.459-1.112 2.459Z"
    />
    <path
      fill="url(#a)"
      d="M1.796 23.407c1.725 1.14 3.939.834 4.944-.686 1.006-1.52.422-3.678-1.303-4.82-1.725-1.14-3.939-.834-4.944.687-1.006 1.52-.423 3.677 1.303 4.819Z"
    />
    <path
      fill="url(#b)"
      d="M37.784 23.407c-1.725 1.142-3.94.834-4.945-.686-1.005-1.52-.422-3.677 1.303-4.819 1.725-1.14 3.94-.834 4.945.686 1.005 1.52.422 3.678-1.303 4.82Z"
    />
    <path
      fill="url(#c)"
      d="M5.659 28.602c2.161-.244 3.754-1.859 3.556-3.607-.197-1.749-2.109-2.968-4.27-2.725-2.161.244-3.754 1.86-3.556 3.608.197 1.748 2.109 2.967 4.27 2.724Z"
    />
    <path
      fill="url(#d)"
      d="M33.934 28.602c-2.161-.244-3.753-1.86-3.556-3.607.197-1.749 2.109-2.968 4.27-2.725 2.162.244 3.754 1.859 3.557 3.607-.197 1.749-2.11 2.968-4.27 2.725Z"
    />
    <path
      fill="url(#e)"
      fillRule="evenodd"
      d="M30.768 29.12a14.661 14.661 0 0 0 3.738-9.8c0-8.128-6.589-14.716-14.716-14.716-8.128 0-14.717 6.588-14.717 14.716 0 3.545 1.254 6.797 3.341 9.337 1.39 4.402 2.2 7.984 2.619 11.984.461 4.408 3.96 8.006 8.393 8.006 4.371 0 7.835-3.506 8.396-7.842.496-3.831 1.388-7.346 2.946-11.685Z"
      clipRule="evenodd"
    />
    <path
      fill="#2D2D2D"
      d="M29 34.848c-2.629 1.416-5.693 2.229-8.965 2.229-3.735 0-7.2-1.059-10.056-2.866.296 1.275.538 2.522.735 3.784 2.707 1.537 5.9 2.425 9.321 2.425 2.938 0 5.709-.655 8.147-1.814.22-1.25.49-2.488.818-3.758ZM27.73 41.468a18.994 18.994 0 0 1-7.704 1.61c-3.266 0-6.325-.809-8.952-2.22a9.41 9.41 0 0 0 .819 2.813 18.955 18.955 0 0 0 8.133 1.808c2.35 0 4.593-.419 6.644-1.18a9.48 9.48 0 0 0 1.06-2.83Z"
    />
    <path
      fill="#000"
      d="M22.584 3.138c.46-.511 1.154-.782 1.795-.884.565-.09 1.15-.057 1.553.11a.538.538 0 1 1-.292.494c-.27-.089-.699-.121-1.172-.046-.568.09-1.122.323-1.464.703-.656.731-.736 1.382-.792 1.842a8.12 8.12 0 0 1-.02.155.282.282 0 1 1-.559-.08c.007-.043.012-.092.019-.145.056-.473.153-1.282.932-2.15ZM17.843.766a.604.604 0 0 1 .58-.766.602.602 0 1 1-.184 1.175c-.402.513-.66.904-.849 1.668-.1.407-.117.89-.095 1.325.023.436.084.802.128.97a.282.282 0 0 1-.546.144 5.988 5.988 0 0 1-.146-1.085c-.024-.463-.008-1.007.111-1.49.224-.906.55-1.37 1.001-1.941Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={2.091}
      d="M17.957 16.834c0 .556.649 1.31 1.827 1.31s1.827-.688 1.84-1.336"
    />
    <path
      fill="#000"
      d="M16.635 15.396a1.04 1.04 0 1 1-2.081 0 1.04 1.04 0 0 1 2.08 0Z"
    />
    <path fill="#fff" d="M16.273 14.9a.27.27 0 1 1-.54 0 .27.27 0 0 1 .54 0Z" />
    <path
      fill="#000"
      d="M25.019 15.396a1.04 1.04 0 1 1-2.08 0 1.04 1.04 0 0 1 2.08 0Z"
    />
    <path
      fill="#fff"
      d="M24.66 14.9a.27.27 0 1 1-.54 0 .27.27 0 0 1 .54 0ZM29.973 11.145c-1.345-1.86-4.311-2.572-6.685-2.374.552.135 2.938.119 5.448 2.628 2.51 2.51 2.464 6.233 2.543 5.086.079-1.147.04-3.481-1.306-5.34Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={3.115}
        x2={3.379}
        y1={18.775}
        y2={23.888}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E7F9FF" />
        <stop offset={1} stopColor="#AEECFF" />
        <stop offset={1} stopColor="#85E2FF" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={36.464}
        x2={36.201}
        y1={18.775}
        y2={23.889}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E7F9FF" />
        <stop offset={1} stopColor="#AEECFF" />
        <stop offset={1} stopColor="#85E2FF" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={4.686}
        x2={6.16}
        y1={23.96}
        y2={28.651}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E7F9FF" />
        <stop offset={1} stopColor="#AEECFF" />
        <stop offset={1} stopColor="#85E2FF" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={34.907}
        x2={33.433}
        y1={23.96}
        y2={28.651}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E7F9FF" />
        <stop offset={1} stopColor="#AEECFF" />
        <stop offset={1} stopColor="#85E2FF" />
      </linearGradient>
      <radialGradient
        id="e"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(90.999 .974 18.802) scale(31.9308 39.6559)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA409" />
        <stop offset={1} stopColor="#EBCA68" />
      </radialGradient>
    </defs>
  </svg>
);
